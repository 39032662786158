<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar class="px-1 bg-black toolbar-title">
        <div slot="start" class="back-images-btn" @click="goBack">
          <ion-icon class="text-white" slot="icon-only" :icon="chevronBackOutline"></ion-icon>
        </div>
        <ion-label class="ml-2 text-white">{{ $t('back') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content
      :scroll-y="true"
      :scroll-x="true"
      color="dark"
      :fullscreen="true"
      class="ion-no-padding bg-black"
      ref="imageContent"
    >
      <div :class="{ 'preview-image': true, zoomed: isZoomed }">
        <ion-img
          :style="isZoomed ? `transform: scale(${zoomLevel})` : ''"
          :src="showSelectedImage"
          :class="{ 'zoom-image': true, zoomed: isZoomed }"
          @click="zoomingImage"
        ></ion-img>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar class="px-1 py-1 bg-black">
        <div class="row end px-1 my-2">
          <ion-icon
            class="text-white prev-next-btn"
            slot="icon-only"
            :icon="chevronBackCircleOutline"
            @click="selectPreviousImage"
          ></ion-icon>
          <ion-icon
            class="text-white prev-next-btn"
            slot="icon-only"
            :icon="chevronForwardCircleOutline"
            @click="selectNextImage"
          ></ion-icon>
        </div>
        <ion-slides class="slides-cate" :options="slideOpts">
          <ion-slide>
            <div v-for="(item, index) in images" :key="index" class="mx-1 img-cover">
              <ion-img
                class="small-img"
                :class="item === showSelectedImage && imageIndex === index ? 'selected-image' : ''"
                :src="item"
                @click="selectedImage(item, index)"
              ></ion-img>
            </div>
          </ion-slide>
        </ion-slides>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import { chevronBackCircleOutline, chevronBackOutline, chevronForwardCircleOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },

  emits: ['close-page'],
  mounted() {
    this.selectedImage(this.images[0], 0);
  },

  setup(props, { emit }) {
    const isTrackingLinkNotValid = ref(false);
    const goBack = () => {
      emit('close-page');
    };
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 'auto'
    };

    const showSelectedImage = ref('');
    const imageIndex = ref(0);
    const selectedImage = (selectedImg, index) => {
      // set zoom back to default first before select/show other image
      zoomLevel.value = 1;
      zoomImage(false);
      (showSelectedImage.value = selectedImg), (imageIndex.value = index);
    };

    const selectNextImage = () => {
      // set zoom back to default first before select/show other image
      zoomLevel.value = 1;
      zoomImage(false);
      imageIndex.value = (imageIndex.value + 1) % props.images?.length;
      selectedImage(props.images[imageIndex.value], imageIndex.value);
    };

    const selectPreviousImage = () => {
      // set zoom back to default first before select/show other image
      zoomLevel.value = 1;
      zoomImage(false);
      imageIndex.value = (imageIndex.value - 1 + props.images?.length) % props.images?.length;
      selectedImage(props.images[imageIndex.value], imageIndex.value);
    };

    const isZoomed = ref(false);
    const imageContent = ref(null);
    const zoomImage = (params) => {
      isZoomed.value = params;
    };
    const setImageToCenter = () => {
      if (imageContent.value) {
        const contentElement = imageContent.value.$el;
        const contentImageHeight = contentElement.clientHeight;
        const contentImageWidth = contentElement.clientWidth;

        // Calculate the center position
        const centerX = contentImageWidth / 2;
        const centerY = contentImageHeight / 2;

        // Scroll to the center position
        contentElement.scrollToPoint(centerX, centerY, 500);
      }
    };
    // default zoom transform is 1
    const zoomLevel = ref(1);
    const zoomingImage = () => {
      if (zoomLevel.value === 1) {
        setImageToCenter();
        // on first touch/click will zoom image and set transform to 2.3
        zoomLevel.value = 2.3;
        zoomImage(true);
      } else {
        // on second touch/click will set zoom image to deafult 1
        zoomLevel.value = 1;
        zoomImage(false);
      }
    };
    return {
      chevronBackOutline,
      chevronBackCircleOutline,
      chevronForwardCircleOutline,
      isTrackingLinkNotValid,
      goBack,
      slideOpts,
      selectedImage,
      showSelectedImage,
      imageIndex,
      isZoomed,
      zoomLevel,
      imageContent,
      zoomImage,
      zoomingImage,
      setImageToCenter,
      selectNextImage,
      selectPreviousImage
    };
  }
});
</script>
<style lang="scss" scoped>
.sf-pro {
  font-family: SF Pro Display;
}
.head-title {
  color: var(--grey-900, #212121);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.grey-lines {
  height: 1px;
  width: 100%;
  background: #e0e0e0;
  background-color: #e0e0e0;
}
.content-space {
  height: 10px;
  width: 100%;
  background: #f5f5f5;
  background-color: #f5f5f5;
}
.column {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
}
.end {
  align-items: center;
  justify-content: space-between;
}
.center-items {
  align-content: center;
  align-items: center;
}
.unpaid {
  color: var(--error-500, #f04438);
  text-align: right;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.1px;
  text-transform: uppercase;
}
.grand-total {
  color: var(--grey-900, #212121);
  text-align: right;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

//order status
.orange {
  background: #eb8c31;
}
.green {
  background: #039855;
}
.red {
  background: #f04438;
}
.grey {
  background: #9e9e9e;
}
.bg-orange {
  background: #fdf5ed;
}
.bg-green {
  background: #ecfdf3;
}
.bg-red {
  background: #fef3f2;
}
.bg-grey-status {
  background: #f5f5f5;
}
.text-orange {
  color: #eb8c31;
}
.text-green {
  color: #039855;
}
.text-red {
  color: #f04438;
}
.text-grey {
  color: #9e9e9e;
}
.title-grey {
  color: #757575;
}

.bg-status {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  padding: 2px 6px;
  font-weight: 700;
}
.dot {
  height: 4px;
  width: 4px;
  border-radius: 100%;
}
.status-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.7px;
}
.time-line {
  height: 15px;
  width: 15px;
  border-radius: 15px;
}
.line-story {
  width: 1.2px;
  flex-grow: 1;
}
.track-line-off {
  width: 1.2px;
  flex-grow: 1;
  margin-bottom: -10px;
  background: #9e9e9e;
}
.track-line-on {
  width: 1.2px;
  flex-grow: 1;
  margin-bottom: -10px;
}
.time-line-green {
  border: 3px solid #039855;
  background: #d1fadf;
}
.time-line-red {
  border: 3px solid #f04438;
  background: #fef3f2;
}
.time-line-grey {
  border: 3px solid #e0e0e0;
  background: #e0e0e0;
}
.track-order-btn {
  border-radius: 10px;
  border: 1.2px solid #00676a;
  --color: #00676a;
  text-transform: capitalize;
}
.tenant-name {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.total-price {
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #000000;
}
.address-info {
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.7px;
  color: #00676a;
}
.text-end {
  text-align: right;
}
.text-start {
  text-align: left;
}
.po-number-and-note-reason {
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  height: fit-content;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
.bill-name {
  color: var(--grey-700, #616161);
  font-weight: 700;
  letter-spacing: 0.5px;
}
.mini-info {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.5px;
}
.po-text {
  font-weight: 600;
  color: black;
}
.product-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin-top: 50%;
}
.not-found-img {
  height: 120px;
  width: 120px;
}
.not-found-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.7px;
}
.try-different {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.7px;
}
.view-all-order-btn {
  border-radius: 10px;
  border: 1.2px solid #00676a;
  color: #00676a;
  text-align: center;
  text-transform: capitalize;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}
.bg-content {
  height: 100%;
  background-color: #f5f5f5;
}
.bg-white {
  background-color: #ffffff;
  padding: 16px;
}
.bg-black {
  background-color: #0000002d;
  --background: #0000002d;
}
.view-driver-location-btn {
  margin-left: -12px;
  margin-top: 4px;
  --inner-padding-bottom: 0px;
  --inner-padding-top: 0px;
  --padding-bottom: 0px;
  --padding-top: 0px;
  --border-radius: 8px;
  --background-activated-opacity: #00676a;
  font-weight: 500;
}
.maps {
  margin-top: -2px;
  margin-left: -2px;
}

.spinner-maps {
  --background: transparent;
}
.back-btn {
  border-radius: 100%;
  font-size: 26px;
  margin-left: -8px;
  --background-activated-opacity: #00676a;
  --color: #00676a;
}
.preview-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  object-fit: contain;
  transform-origin: center;
}
.preview-image.zoomed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 200vh;
  width: 260vw;
  transform-origin: center;
}
.small-img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 8px;
  overflow: hidden;
}

.zoom-image {
  width: 100vw;
  height: 100vh;
  transition: transform 0.5s ease-in-out;
  transform-origin: center;
}
.selected-image {
  border: 2px solid #039855;
}
.back-images-btn {
  height: 32px;
  width: 32px;
  font-size: 26px;
  background: #bdbdbd;
  background-color: #bdbdbd;
  color: #ffffff;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-white {
  color: #ffffff;
}

.slides-cate {
  overflow: auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.prev-next-btn {
  height: 24px;
  width: 24px;
}
.img-cover {
  border-radius: 8px;
  overflow: hidden;
}
.toolbar-title {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: center;
}
</style>
